export const SITE_NAME = 'Pegase';
export const SITE_DOMAIN = 'pegase.skin';

export const CONSTANT_MAIL_MANAGERS = ['stunaz@gmail.com'];
export const CONSTANT_MAIL_FROM = `${SITE_NAME} <no-reply@${SITE_DOMAIN}>`;

export enum languageEnum {
  en = 'en',
  fr = 'fr',
  ru = 'ru',
}

export enum gradeEnum {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  NEW = 'N',
}

export const FrequencyPeriod = {
  DAILY: 3,
  WEEKLY: 2,
  MONTHLY: 1,
  YEARLY: 0,
};

export const MAP_DEFAULT_ZOOM = 14;

export const defaultUID = '00000000-0000-0000-0000-000000000000';

export const MAX_POPOVER_NOTIFICATION = 3;

export const PAGE_SIZE = 10;

export const defaultName = 'guest';

export const defaultLanguage = languageEnum.en;
