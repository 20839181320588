'use client';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import HomeRepairServiceRoundedIcon from '@mui/icons-material/HomeRepairServiceRounded';
import ShoppingCartCheckoutRoundedIcon from '@mui/icons-material/ShoppingCartCheckoutRounded';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import type { z } from 'zod';

import dayjs from '@pegase/helpers/utils/date';
import { type notificationSchema, notificationTypeEnum } from '@pegase/helpers/validators/notification';
import { useI18n } from '@pegase/i18n/client';

import { useTRPC } from '~/shared/trpc/react';

interface Props {
  handleClick?: () => void;
  notification: z.infer<typeof notificationSchema>;
}

export const Notification = (props: Props) => {
  const trpc = useTRPC();
  const t = useI18n();
  const router = useRouter();
  const queryClient = useQueryClient();
  const mutation = useMutation(trpc.notification.delete.mutationOptions());

  const timeAgo: string | null = props.notification.duOn
    ? t('common.due_ago', { timeAgo: dayjs(props.notification.duOn).from(dayjs()) })
    : null;

  const _delete = () => {
    mutation.mutate(props.notification, {
      onSettled: () => {
        void queryClient.refetchQueries(trpc.notification.all.queryOptions());
      },
    });
  };

  const _click = () => {
    if (props.notification.link) {
      void router.push(props.notification.link);
    }

    if (props.handleClick) {
      props.handleClick();
    }
  };

  return (
    <ListItem
      secondaryAction={
        props.notification.dismissible ? (
          <IconButton aria-label={t('common.done')} onClick={_delete}>
            <CheckRoundedIcon />
          </IconButton>
        ) : null
      }
      disablePadding>
      <ListItemButton onClick={_click}>
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: 'primary.main' }} variant="rounded">
            {props.notification.type === notificationTypeEnum.PROFESSION_PUBLISHED && <HomeRepairServiceRoundedIcon />}
            {props.notification.type === notificationTypeEnum.PROFESSION_CREATED && <HomeRepairServiceRoundedIcon />}
            {props.notification.type === notificationTypeEnum.PROFESSION_SUBMITTED && <HomeRepairServiceRoundedIcon />}
            {props.notification.type === notificationTypeEnum.BOOKING_CREATED && <ShoppingCartCheckoutRoundedIcon />}
            {props.notification.type === notificationTypeEnum.BOOKING_SUBMITTED && <ShoppingCartCheckoutRoundedIcon />}
            {props.notification.type === notificationTypeEnum.BOOKING_CONFIRMED && <ShoppingCartCheckoutRoundedIcon />}
            {props.notification.type === notificationTypeEnum.BOOKING_DECLINED && <ShoppingCartCheckoutRoundedIcon />}
            {props.notification.type === notificationTypeEnum.BOOKING_SCHEDULED && <ShoppingCartCheckoutRoundedIcon />}
            {props.notification.type === notificationTypeEnum.BOOKING_CANCELED && <ShoppingCartCheckoutRoundedIcon />}
            {props.notification.type === notificationTypeEnum.BOOKING_REVIEW && <ShoppingCartCheckoutRoundedIcon />}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={props.notification.message} secondary={timeAgo} />
      </ListItemButton>
    </ListItem>
  );
};
