const getBaseUrl = () => '';

/**
 * Get the image URL based on the provided image and default image
 * @param {string} defaultImage - The default image URL
 * @param {string | null | undefined} image - The image URL or null/undefined if not provided
 * @returns {string} - The resolved image URL
 */
export default function getImageUrl(defaultImage: string, image: string | null | undefined): string {
  // If no image is provided, use the default image URL
  if (image === null || image === undefined || image === '') {
    return `${getBaseUrl()}${defaultImage}`;
  }

  // If the image URL starts with '/_next', resolve the full URL using the base URL
  if (image.startsWith('/_next')) {
    return `${getBaseUrl()}${image}`;
  }

  // If the image URL starts with 'http', it is already a fully qualified URL
  if (image.startsWith('http')) {
    return image;
  }

  // If the image URL does not meet any of the above conditions, use a default image hosting service
  return `https://utfs.io/f/${image}`;
}
