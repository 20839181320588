'use client';
import * as React from 'react';

import TranslateRoundedIcon from '@mui/icons-material/TranslateRounded';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import dayjs from '@pegase/helpers/utils/date';
import { useChangeLocale, useI18n } from '@pegase/i18n/client';

const HeaderLanguage = () => {
  const t = useI18n();
  const changeLocale = useChangeLocale();

  const [menuLanguageOpen, setMenuLanguageOpen] = React.useState(false);
  const [menuLanguageAnchorEl, setMenuLanguageAnchorEl] = React.useState<null | HTMLElement>(null);

  const _language = (language: 'fr' | 'en' | 'ru') => {
    changeLocale(language);
    dayjs.locale(language);
    setMenuLanguageOpen(false);
  };

  const _openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuLanguageAnchorEl(event.currentTarget);
    setMenuLanguageOpen(true);
  };

  const _closeMenu = () => setMenuLanguageOpen(false);

  const _frSelected = (_event: React.MouseEvent<HTMLElement>) => {
    _language('fr');
  };

  const _enSelected = (_event: React.MouseEvent<HTMLElement>) => {
    _language('en');
  };

  const _ruSelected = (_event: React.MouseEvent<HTMLElement>) => {
    _language('ru');
  };

  return (
    <>
      <IconButton
        aria-label={t('common.language')}
        aria-owns={menuLanguageOpen ? 'language-menu' : undefined}
        aria-haspopup="menu"
        onClick={_openMenu}
        color="inherit">
        <TranslateRoundedIcon />
      </IconButton>
      <Menu
        id="language-menu"
        role="menu"
        anchorEl={menuLanguageAnchorEl}
        open={menuLanguageOpen}
        onClose={_closeMenu}
        transitionDuration={200}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        slotProps={{
          paper: {
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 22,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}>
        <MenuItem dense value={'en'} onClick={_enSelected}>
          English
        </MenuItem>
        <MenuItem dense value={'fr'} onClick={_frSelected}>
          Français
        </MenuItem>
        <MenuItem dense value={'ru'} onClick={_ruSelected}>
          Русский
        </MenuItem>
      </Menu>
    </>
  );
};

export default HeaderLanguage;
