'use client';

import * as React from 'react';

import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { useQuery } from '@tanstack/react-query';

import { useI18n } from '@pegase/i18n/client';

import NotificationPopover from '~/modules/notification/notification-popover';
import { useTRPC } from '../trpc/react';

const HeaderNotification = () => {
  const trpc = useTRPC();
  const t = useI18n();
  const [menuNotification, setMenuNotification] = React.useState<{
    open: boolean;
    anchor: null | HTMLElement;
  }>({
    open: false,
    anchor: null,
  });

  const query = useQuery(
    trpc.notification.all.queryOptions(undefined, {
      refetchInterval: 1000 * 60 * 10, // every 10 minutes
    }),
  );

  const _closeNotification = () => setMenuNotification({ open: false, anchor: null });
  const _openNotification = (event: React.MouseEvent<HTMLElement>) => {
    setMenuNotification({ open: true, anchor: event.currentTarget });
  };

  if (!query.data) {
    return null;
  }

  return (
    <>
      {query.data.length > 0 && (
        <Zoom in>
          <Tooltip title={t('common.notifications')} enterDelay={300}>
            <IconButton color="inherit" onClick={_openNotification}>
              <Badge badgeContent={query.data.length} color="secondary">
                <NotificationsActiveRoundedIcon />
              </Badge>
            </IconButton>
          </Tooltip>
        </Zoom>
      )}

      {query.data.length > 0 && (
        <Popover
          open={menuNotification.open}
          anchorEl={menuNotification.anchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={_closeNotification}>
          <NotificationPopover onClickAway={_closeNotification} notifications={query.data} />
        </Popover>
      )}
    </>
  );
};

export default HeaderNotification;
