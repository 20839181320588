import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import { Link } from 'next-view-transitions';

import { useI18n } from '@pegase/i18n/client';

interface Props {
  handleClick: () => void;
}

const More = (props: Props) => {
  const t = useI18n();

  const _click = () => {
    props.handleClick();
  };

  return (
    <ListItem key="_more" sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Button component={Link} href="/account/notification" color="secondary" onClick={_click}>
        {t('common.view_more')}
      </Button>
    </ListItem>
  );
};

export default More;
