import { Fragment } from 'react';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import type { z } from 'zod';

import { MAX_POPOVER_NOTIFICATION } from '@pegase/helpers/utils/constants';
import type { notificationSchema } from '@pegase/helpers/validators/notification';

import More from './more';
import { Notification } from './notification';

interface Props {
  notifications: z.infer<typeof notificationSchema>[];
  onClickAway: () => void;
}

const NotificationPopover = (props: Props) => {
  const notificationsList = props.notifications.slice(0, MAX_POPOVER_NOTIFICATION);

  const _click = () => {
    props.onClickAway();
  };

  return (
    <Paper>
      <List dense>
        {notificationsList.map((notification: z.infer<typeof notificationSchema>, key) => (
          <Fragment key={notification.id}>
            <Notification notification={notification} handleClick={_click} />
            {key + 1 !== notificationsList.length ? <Divider variant="middle" /> : null}
          </Fragment>
        ))}
        {props.notifications.length > MAX_POPOVER_NOTIFICATION && <More handleClick={_click} />}
      </List>
    </Paper>
  );
};

export default NotificationPopover;
