import dayjs from 'dayjs';

import 'dayjs/locale/fr';
import 'dayjs/locale/ru';

import calendar from 'dayjs/plugin/calendar';
import isBetween from 'dayjs/plugin/isBetween';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

/**
 * export default dayjs with plugin and localization
 */

dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(calendar);

/**
 *
 * @param value given date
 * @returns true is value is parsable as a date else false
 */
export const isValid = (value: string) => {
  // return false if it is an empty string
  if (value.trim() === '') {
    return false;
  }

  return dayjs(value).isValid();
};

export type Dayjs = dayjs.Dayjs;

export type { ConfigType } from 'dayjs';

export default dayjs;
